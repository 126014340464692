import { Component, OnInit } from '@angular/core';
import { browserRefresh } from 'app/app.component';
import { LoginService } from '../shared/auth/login.service';

@Component({
  selector: 'app-instruccions',
  templateUrl: './instruccions.component.html',
  styleUrls: ['./instruccions.component.css']
})
export class InstruccionsComponent implements OnInit {
  browserRefresh: boolean;

  constructor( private login: LoginService ) { }

  ngOnInit(): void {
    this.browserRefresh = browserRefresh;
    if ( browserRefresh ) {
      this.login.logOut();
    }
  }

}
