<div *ngIf="isAdmin">
    <!--Extended Table starts-->
    <div class="row text-left">
        <div class="col-6">
            <div class="content-header">Registre d'accessos</div>
            <p class="content-sub-header">Accés usuaris al Kiwibot</p>
        </div>
        <div class="col-6">
            <div class="content-header text-right">
                <button class="btn btn-outline-danger" (click)="delRegis()"><i class="ft-trash font-medium-3 p-1"></i> Eliminar Registres</button>
            </div>
        </div>
    </div>

    <section id="extended">
        <div class="row text-left">
            <div class="col-sm-12">
                <div class="card">
    
                    <div class="card-content">
                        <div class="card-body">
                            <table class="table table-responsive-md text-center table-bordered table-sm row-border hover" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions">
                                <thead>
                                    <tr>
                                        <th>Usuari</th>
                                        <th>Email</th>
                                        <th>Data i hora accés</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of regis">
                                        <td>{{r.user.username}}</td>
                                        <td>{{r.user.email}}</td>
                                        <td>{{r.datetime | date: 'dd/MM/yy HH:mm:ss'}}</td>
                                    </tr>
                                </tbody>
                            </table>
    
                            <div class="row text-left" *ngIf="!regis?.length && !flagNull">
                                <div class="col-sm-12">
                                    <ngx-skeleton-loader count="10" animation="pulse"></ngx-skeleton-loader>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>