import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/auth/login.service';

@Component({
    selector: 'app-login-component',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

    validationsForm: FormGroup;

    validationMessages = {
        usuari: [
            { type: 'required', message: 'El usuari és obligatori.' },
            { type: 'minlength', message: 'Usuari mínim 4 caràcters.' }
        ],
        password: [
            { type: 'required', message: 'El password és obligatori.' },
            { type: 'minlength', message: 'Password mínim 4 caràcters.' }
        ]
    };

    loginError = '';

    constructor( private formBuilder: FormBuilder,
                 private loginService: LoginService
                 ) { }

    ngOnInit() {
        this.validationsForm = this.formBuilder.group({
            usuari: new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(4),
            ])),
            password: new FormControl('', Validators.compose([
            Validators.minLength(4),
            Validators.required
            ])),
        });
    }

    loginUser(value: any) {
      this.loginService.logIn(value);
      this.loginService.loginError.subscribe( err => {
        this.loginError = err;
        // console.log(err);
      });
    }

}
