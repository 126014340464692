import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from './login.service'

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
      public login: LoginService,
      private router: Router
        ) {}

    canActivate(): boolean {
      // console.log('auth?: ', this.login.isAuthenticated());
      if ( !this.login.isAuthenticated() ) {
        this.router.navigate(['login']);
        return false;
      }

      return this.login.isAuthenticated();
    }

}