import { Component, OnInit } from '@angular/core';
import { UsuarisService } from '../services/usuaris.service';
import swal from 'sweetalert2';
import { LoginService } from '../shared/auth/login.service';
import { browserRefresh } from '../app.component';

@Component({
  selector: 'app-perfils',
  templateUrl: './perfils.component.html',
  styleUrls: ['./perfils.component.css']
})
export class PerfilsComponent implements OnInit {

  usuaris = [];
  browserRefresh: boolean;
  constructor( private users: UsuarisService,
               private login: LoginService ) { }

  ngOnInit(): void {

    this.browserRefresh = browserRefresh;
    if ( browserRefresh ) {
      this.login.logOut();
    }

    this.getUsers();
  }

  getUsers() {
    this.users.GetUsuaris()
    .subscribe( ( data ) => {
        this.usuaris = data['usuaris'];
    },
        (error) => {
            console.error(error);
        }
    );
  }

  delUser(id) {
    const u = this.usuaris.find(x => x._id === id);

    let usuari = this.login.getUser();

    if ( usuari !== u.username ) {
      swal.fire({
          title: 'Eliminar ' + u.username + '?',
          text: "Segur que vols eliminar l'usuari?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Eliminar',
          cancelButtonText: 'Cancel·lar'
      }).then((result) => {
          if (result.value) {
              this.users.DelUsuaris(id).subscribe( res => {
                if ( res['ok'] ) {
                  swal.fire(
                    'Usuari eliminat!',
                    "L'usuari ha estat eliminat de la base de dades.",
                    'success'
                  );
                  this.getUsers();
                } else {
                    swal.fire('Error', 'Error al eliminar!', 'error');
                }

              });
          }
      });
    } else {
      swal.fire('Error', 'No pots eliminar el usuari amb el que has entrat!', 'error');
    }
  }

  addUser() {
    let n: any;
    let e: any;
    let p: any;

    swal.mixin({
        input: 'text',
        confirmButtonText: 'Següent &rarr;',
        showCancelButton: true,
        progressSteps: ['1', '2', '3']
    }).queue([
        {
            title: 'Nom',
            input: 'text',
            inputValidator: (value) => {
                return value.length < 4 && 'Usuari ha de contenir més de 4 carácters'
              },
            text: "Introdueix nom d'usuari (més de 4 caràcters)"
        },
        {
          title: 'Password',
          input: 'password',
          inputValidator: (value) => {
            return value.length < 4 && 'La contrasenya ha de contenir més de 4 carácters'
          },
          text: 'Introdueix la contrasenya (més de 4 caràcters)'
        },
        {
          title: 'Email',
          input: 'email',
          text: 'Introdueix correu electrònic'
        }
    ]).then((result) => {
        if (result.value) {
          n = result.value[0];
          e = result.value[2];
          p = result.value[1];

          this.users.AddUsuaris(n, e, p).subscribe( res => {
            if ( res['ok'] ) {
              swal.fire({
                title: 'Usuari creat!',
                html:
                    'Usuari ' + n + ' guardat correctament',
                confirmButtonText: 'Okey!'
              })
              this.getUsers();
            } else {
              swal.fire('Error', 'Error al crear usuari!', 'error');
            }
          });

        }
    })
  }

}
