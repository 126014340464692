import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CalibresService } from '../services/calibres.service';

import { LoginService } from '../shared/auth/login.service';

import swal from 'sweetalert2';
import { browserRefresh } from 'app/app.component';

@Component({
  selector: 'app-calibres',
  templateUrl: './calibres.component.html',
  styleUrls: ['./calibres.component.css']
})
export class CalibresComponent implements OnInit, OnDestroy {

  dtOptions: DataTables.Settings = {};

  @ViewChild(DataTableDirective)

  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  calibres = [];

  flagNull = false;
  browserRefresh: boolean;

  constructor( private servei: CalibresService, private login: LoginService ) { }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit() {

    this.browserRefresh = browserRefresh;
    if ( browserRefresh ) {
      this.login.logOut();
    }

    this.getCalibres();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: [[ 0, 'asc' ]],
      language: {
        'decimal': ',',
        'emptyTable': 'Sense informació',
        'info': 'De _START_ a _END_ de _TOTAL_ Registres',
        'infoEmpty': 'Mostrant 0 a 0 de 0 Registres',
        'infoFiltered': '(Filtrat de _MAX_ total registres)',
        'infoPostFix': '',
        'thousands': '.',
        'lengthMenu': 'Mostrar _MENU_ Registres',
        'loadingRecords': 'Carregant...',
        'processing': 'Processant...',
        'search': 'Cercar:',
        'zeroRecords': 'Sense resultats',
        'paginate': {
            'first': 'Primer',
            'last': 'Darrer',
            'next': 'Següent',
            'previous': 'Anterior'
        }
      }
    };
  }

  async getCalibres() {
    await this.servei.GetCalibres()
    .subscribe( ( data ) => {
        this.calibres = data['calibres'];
        if (this.calibres.length < 1) {
          this.flagNull = true;
        }
        this.dtTrigger.next();
    },
        (error) => {
            console.error(error);
        }
    );
  }

  delCalibre(calibre) {
    swal.fire({
      title: 'Eliminar ' + calibre.numcal + '?',
      text: "Segur que vols eliminar el calibre?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancel·lar'
    }).then((result) => {
      if (result.value) {
        this.servei.DelCalibre(calibre._id).subscribe( res => {
            this.eliminaCalibreLlista(calibre._id);
            swal.fire(
              'Calibre eliminat!',
              'El calibre ha estat eliminat de la base de dades.',
              'success'
            );
        }, error => {
          console.log(error);
          const e = error.error.err.message || 'error desconegut';
          swal.fire('Error', e, 'error');
        })
        ;
      }
    });
  }

  eliminaCalibreLlista(calibre) {
    this.calibres = this.calibres.filter(cal => cal._id !== calibre);
    if (this.calibres.length < 1) {
      this.flagNull = true;
    }
    this.rerender();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
