import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  authState = new BehaviorSubject(this.checkLoginStatus());
  userName = new BehaviorSubject<string>(localStorage.getItem('usuari'));

  loginError = new BehaviorSubject<string>('ok');

  constructor( private http: HttpClient,
               private router: Router ) { }

  isAuthenticated() {
    return this.authState.value;
  }

  checkLoginStatus(): boolean {
    const loginCookie = localStorage.getItem('jwt');

    if ( loginCookie !== null ) {
      return true;
    }
    return false;
  }

  getUser() {
    const user = localStorage.getItem('usuari');
    return user;
  }

  getUserId() {
    const iduser = localStorage.getItem('iduser');
    return iduser;
  }

  logIn(value) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      })
    };

    const postData = {
      username: value.usuari,
      password: value.password
    };

    this.http.post(environment.serverUrl + '/login', postData, httpOptions).subscribe( data => {

        if ( data['ok'] ) {
          const userLoged = {
            username: postData.username,
            iduser: data['usuari']._id,
            token: data['token']
          };

          console.log('Autoritzat!');

        this.authState.next(true);
        localStorage.setItem('usuari', userLoged.username);
        localStorage.setItem('iduser', userLoged.iduser);
        localStorage.setItem('jwt', userLoged.token);
        localStorage.setItem('log', '1');
        this.router.navigate(['dashboard']);

        } else {
          console.error('Error data auth!');
          console.error(data);
        }

    },
      (err) => {
        console.error('Error auth!');
        console.log(err);
        this.loginError.next(err.error.err.message);
      }
    );

  }

  logOut() {
    this.authState.next(false);
    localStorage.removeItem('usuari');
    localStorage.removeItem('iduser');
    localStorage.removeItem('jwt');
    this.router.navigate(['login']);
    //location.reload();
  }

}
