import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { DataTableDirective } from 'angular-datatables';

import { ClientsService } from 'app/services/clients.service';

import { Subject } from 'rxjs';
import { LoginService } from '../shared/auth/login.service';

import swal from 'sweetalert2';
import { browserRefresh } from 'app/app.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit, OnDestroy {

  dtOptions: DataTables.Settings = {};

  @ViewChild(DataTableDirective)

  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  clients = [];

  flagNull = false;
  browserRefresh: boolean;

  constructor( private servei: ClientsService, private login: LoginService ) {}

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit() {

    this.browserRefresh = browserRefresh;
    if ( browserRefresh ) {
      this.login.logOut();
    }

    this.getClients();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      columnDefs: [ { type: 'date-euro', 'targets': [4] } ],
      order: [[ 4, 'desc' ]],
      language: {
        'decimal': ',',
        'emptyTable': 'Sense informació',
        'info': 'De _START_ a _END_ de _TOTAL_ Registres',
        'infoEmpty': 'Mostrant 0 a 0 de 0 Registres',
        'infoFiltered': '(Filtrat de _MAX_ total registres)',
        'infoPostFix': '',
        'thousands': '.',
        'lengthMenu': 'Mostrar _MENU_ Registres',
        'loadingRecords': 'Carregant...',
        'processing': 'Processant...',
        'search': 'Cercar:',
        'zeroRecords': 'Sense resultats',
        'paginate': {
            'first': 'Primer',
            'last': 'Darrer',
            'next': 'Següent',
            'previous': 'Anterior'
        }
      }
    };
  }

  async getClients() {
    await this.servei.GetClients()
    .subscribe( ( data ) => {
        this.clients = data['clients'];
        if (this.clients.length < 1) {
          this.flagNull = true;
        }
        this.dtTrigger.next();
    },
        (error) => {
            console.error(error);
        }
    );
  }

  delClient(client) {
    swal.fire({
      title: 'Eliminar ' + client.nom + '?',
      text: "Segur que vols eliminar el client?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancel·lar'
    }).then((result) => {
        if (result.value) {
          this.servei.DelClient(client._id).subscribe( res => {
              this.eliminaClientLlista(client._id);
              swal.fire(
                'Client eliminat!',
                'El client ha estat eliminat de la base de dades.',
                'success'
              );
          }, error => {
            console.log(error);
            const e = error.error.err.message || 'error desconegut';
            swal.fire('Error', e, 'error');
          });
        }
    });
  }

  eliminaClientLlista(client) {
    this.clients = this.clients.filter(cli => cli._id !== client);
    if (this.clients.length < 1) {
      this.flagNull = true;
    }
    this.rerender();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
   }

}
