import { Routes, RouterModule } from '@angular/router';
import { PerfilsComponent } from '../../perfils/perfils.component';
import { PercentComponent } from '../../percent/percent.component';
import { InstruccionsComponent } from '../../instruccions/instruccions.component';
import { VendesComponent } from '../../vendes/vendes.component';
import { NovaComponent } from '../../vendes/nova/nova.component';
import { RegistresComponent } from '../../registres/registres.component';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'vendes',
    // component: VendesComponent
    loadChildren: () => import('../../vendes/vendes.module').then(m => m.VendesModule)
  },
  // {
  //   path: 'novavenda',
  //   component: NovaComponent
  // },
  {
    path: 'empreses',
    component: PercentComponent
  },
  {
    path: 'perfils',
    component: PerfilsComponent
  },
  {
    path: 'calibres',
    loadChildren: () => import('../../calibres/calibres.module').then(m => m.CalibresModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('../../clients/clients.module').then(m => m.ClientsModule)
  },
  {
    path: 'instruccions',
    component: InstruccionsComponent
  },
  {
    path: 'registres',
    component: RegistresComponent
  }
];
