import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  LOCALE_ID,
} from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { VendesService } from "../services/vendes.service";

import { LoginService } from "../shared/auth/login.service";

import swal from "sweetalert2";
import { browserRefresh } from "app/app.component";

@Component({
  selector: "app-vendes",
  templateUrl: "./vendes.component.html",
  styleUrls: ["./vendes.component.css"],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})
export class VendesComponent implements OnInit, OnDestroy {
  dtOptions: any = {};

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  vendes: any = [];

  flagNull = false;
  browserRefresh: boolean;

  constructor(private servei: VendesService, private login: LoginService) {}

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit() {
    this.browserRefresh = browserRefresh;
    if (browserRefresh) {
      this.login.logOut();
    }

    this.getVendes();
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 50,
      processing: true,
      columnDefs: [{ type: "date-euro", targets: [6] }],
      order: [[6, "desc"]],
      language: {
        decimal: ",",
        emptyTable: "Sense informació",
        info: "De _START_ a _END_ de _TOTAL_ Registres",
        infoEmpty: "Mostrant 0 a 0 de 0 Registres",
        infoFiltered: "(Filtrat de _MAX_ total registres)",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "Mostrar _MENU_ Registres",
        loadingRecords: "Carregant...",
        processing: "Processant...",
        search: "Cercar:",
        zeroRecords: "Sense resultats",
        paginate: {
          first: "Primer",
          last: "Darrer",
          next: "Següent",
          previous: "Anterior",
        },
      },
      dom: "Blfrtip",
      buttons: [
        { extend: "excel", text: "Exportar a Excel" },
        { extend: "csv", text: "Exportar a CSV" },
        { extend: "print", text: "Imprimir" },
      ],
    };
  }

  async getVendes() {
    await this.servei.GetVendes().subscribe(
      (data) => {
        this.vendes = data["vendes"];
        if (this.vendes.length < 1) {
          this.flagNull = true;
        }
        this.dtTrigger.next();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  delVenda(venda) {
    swal
      .fire({
        title: "Eliminar " + venda.ref + "?",
        text: "Segur que vols eliminar la venda?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancel·lar",
      })
      .then((result) => {
        if (result.value) {
          this.servei.DelVenda(venda._id).subscribe(
            (res) => {
              this.eliminaVendaLlista(venda._id);
              swal.fire(
                "Venda eliminada!",
                "La venda ha estat eliminada de la base de dades.",
                "success"
              );
            },
            (error) => {
              console.log(error);
              const e = error.error.err.message || "error desconegut";
              swal.fire("Error", e, "error");
            }
          );
        }
      });
  }

  eliminaVendaLlista(venda) {
    this.vendes = this.vendes.filter((vend) => vend._id !== venda);
    if (this.vendes.length < 1) {
      this.flagNull = true;
    }
    this.rerender();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
