<!--Extended Table starts-->
<div class="row text-left">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <div class="content-header">Gestió Clients</div>
                <p class="content-sub-header">Agenda de clients de Prosmokiwi</p>
            </div>
            <div class="col-6">
                <div class="content-header text-right">
                    <button class="btn btn-outline-primary" routerLink="nouclient"><i class="icon icon-user-follow font-medium-3 p-1"></i> Afegir Client</button>
                </div>
            </div>
        </div>
    </div>
</div>
<section id="extended">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="card">

                <div class="card-content">
                    <div class="card-body">
                        <table class="table table-responsive-md text-center table-bordered table-sm row-border hover" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions">
                            <thead>
                                <tr>
                                    <th>Client</th>
                                    <th>Zona</th>
                                    <th style="width: 100px">Telèfon</th>
                                    <th>Especial</th>
                                    <th style="min-width: 120px">Registre</th>
                                    <th>Accions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of clients">
                                    <td>{{ c.nom }}</td>
                                    <td>{{ c.zona }}</td>
                                    <td>{{ c.telefon }}</td>
                                    <td>
                                        <div *ngIf="c.preuespecial; else elseBlock">Si</div>
                                        <ng-template #elseBlock>No</ng-template>
                                    </td>
                                    <td>{{ c.datareg | date: 'dd/MM/yy HH:mm:ss' }}</td>
                                    <td>
                                        <a [routerLink]="['editaclient', c._id]" class="success p-0 mr-2 bto-actions">
                                            <i class="icon icon-pencil font-medium-3 p-1"></i>
                                        </a>
                                        <a class="danger p-0 bto-actions" (click)="delClient(c)">
                                            <i class="icon icon-trash font-medium-3 p-1"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="row text-left" *ngIf="!clients?.length && !flagNull">
                            <div class="col-sm-12">
                                <ngx-skeleton-loader count="10" animation="pulse"></ngx-skeleton-loader>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Extended Table Ends-->