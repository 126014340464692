<!--Login Page Starts-->
<section id="login">
    <div class="container-fluid">
        <div class="row full-height-vh m-0">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body login-img">
                            <div class="row m-0">
                                <div class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle">
                                    <img src="assets/img/gallery/login.png" alt="" class="img-fluid mt-4" width="400" height="230" />
                                </div>
                                <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                                    <h4 class="card-title mb-2">Kiwibot 2021</h4>
                                    <p class="card-text mb-3">
                                        Introdueix les credencials d'accés
                                    </p>
                                    <div class="validation-errors ">
                                        <div class="error-message" *ngIf="loginError !== 'ok'">
                                            <p class="msg-error-acces danger text-center">
                                                {{ loginError }}
                                            </p>
                                        </div>
                                    </div>
                                    <form [formGroup]="validationsForm" (ngSubmit)="loginUser(validationsForm.value)">
                                        <input type="text" formControlName="usuari" class="form-control mb-2" placeholder="Username" />

                                        <div class="validation-errors ">
                                            <ng-container *ngFor="let validation of validationMessages.usuari">
                                                <div class="error-message" *ngIf="validationsForm.get('usuari').hasError(validation.type) && (validationsForm.get('usuari').dirty || validationsForm.get('usuari').touched)">
                                                    <p class="msg-validacio">
                                                        {{ validation.message }}
                                                    </p>
                                                </div>
                                            </ng-container>
                                        </div>

                                        <input type="password" formControlName="password" class="form-control mb-2" placeholder="Password" />

                                        <div class="validation-errors ">
                                            <ng-container *ngFor="let validation of validationMessages.password ">
                                                <div class="error-message" *ngIf="validationsForm.get( 'password').hasError(validation.type) && (validationsForm.get( 'password').dirty || validationsForm.get( 'password').touched) ">
                                                    <p class="msg-validacio">
                                                        {{ validation.message }}
                                                    </p>
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="fg-actions ">
                                            <button type="submit" [disabled]="!validationsForm.valid" class="btn btn-outline-success btn-lg btn-block mt-4">
                                                <i class="icon-key" *ngIf="!validationsForm.valid"></i>
                                                 Entrar <i class="icon-login" *ngIf="validationsForm.valid"></i>
                                            </button>
                                        </div>
                                    </form>
                                    <!-- btn btn-outline-success btn-lg btn-block btn-round mt-3 -->
                                    <hr class="m-0 mt-5" />
                                    <div class="text-center mt-2 mb-3">
                                        <div class="option-login ">
                                            <h6 class="text-decoration-none text-primary ">
                                                Desenvolupat per Prosite.dev
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Page Ends-->