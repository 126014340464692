<!--Extended Table starts-->
<div class="row text-left">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <div class="content-header">Gestió Vendes</div>
                <p class="content-sub-header">Llistat vendes temporada actual</p>
            </div>
            <div class="col-6">
                <div class="content-header text-right">
                    <button class="btn btn-outline-primary" routerLink="novavenda"><i class="ft-plus font-medium-3 p-1"></i> Afegir Venda</button>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="extended">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <table class="table table-responsive-md text-center table-bordered table-sm row-border hover" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Client</th>
                                    <th style="width: 100px">Calibre/Pes/Preu</th>
                                    <th>Kg</th>
                                    <th>Preu/kg</th>
                                    <th>Import</th>
                                    <th style="width: 120px">Data</th>
                                    <th>Accions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let v of vendes">
                                    <td>{{ v.ref }}</td>
                                    <td>{{ v.client_id.nom }}</td>
                                    <td>{{ v.calibre_id.numcal }} / {{ v.calibre_id.pesos }} / {{ v.calibre_id.preu }}€</td>
                                    <td>{{ v.kg }}kg</td>
                                    <td>{{ v.preukg }}€</td>
                                    <td>{{ v.import | number:'1.0-2' }}€</td>
                                    <td>{{ v.data_venda | date: 'dd/MM/yy HH:mm:ss' }}</td>
                                    <td>
                                        <a [routerLink]="['editavenda', v._id]" class="success p-0 mr-2 bto-actions">
                                            <i class="icon icon-pencil font-medium-3 p-1"></i>
                                        </a>
                                        <a class="danger p-0 bto-actions" (click)="delVenda(v)">
                                            <i class=" icon icon-trash font-medium-3 p-1 "></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="row text-left" *ngIf="!vendes?.length && !flagNull">
                            <div class="col-sm-12">
                                <ngx-skeleton-loader count="10" animation="pulse"></ngx-skeleton-loader>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
<!--Extended Table Ends-->