import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    { path: '/dashboard', title: 'Principal',isadmin: false, icon: 'icon-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/vendes', title: 'Vendes',isadmin: false, icon: 'icon-bag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/vendes/novavenda', title: 'Nova Venda',isadmin: false, icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '', title: 'Configurar Dades',isadmin: false, icon: 'icon-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/empreses', title: 'Inicialitzar',isadmin: false, icon: 'icon-pie-chart', class: 'second-level', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '', title: 'Clients',isadmin: false, icon: 'icon-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                { path: '/clients', title: 'Llistat',isadmin: false, icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/clients/nouclient', title: 'Nou Client',isadmin: false, icon: 'icon-user-follow', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
            ] },
            { path: '', title: 'Calibres',isadmin: false, icon: 'ft-bar-chart-2', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                { path: '/calibres', title: 'Llistat',isadmin: false, icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/calibres/noucalibre', title: 'Nou Calibre',isadmin: false, icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
            ] },
            { path: '/perfils', title: 'Perfils',isadmin: false, icon: 'icon-user-following', class: 'second-level', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    { path: '/instruccions', title: 'Instruccions',isadmin: false, icon: 'icon-support', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/registres', title: 'Registres',isadmin: true, icon: 'icon-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
];
