<!--Error page starts-->
<section id="error">
    <div class="container-fluid forgot-password-bg">
        <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <img src="../../assets/img/gallery/error.png" alt="" class="img-fluid error-img mt-2" height="408" width="500">
                        <h1 class="text-white mt-4">404 - No s'ha trobat la pàgina!</h1>
                        <button class="btn btn-primary btn-lg mt-3"><a [routerLink]="['/dashboard']" class="text-decoration-none text-white">Tornar al Home</a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Error page ends-->