import { Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LogService {

  baseurl = environment.serverUrl;

  token = localStorage.getItem('jwt');

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'X-Access-Token': this.token })
  };

  constructor( private http: HttpClient ) { }

   // GET
  GetLog() {
    return this.http.get(this.baseurl + '/log', this.httpOptions);
  }

  // POST
  SetLog(id) {
    const ide =  {
      "iduser": id
    }
    return this.http.post(this.baseurl + '/log', ide, this.httpOptions);
  }

  // DELETE
  delRegis() {
    return this.http.delete(this.baseurl + '/log', this.httpOptions);
  }

  // AVIS PER EMAIL
  MailLogin(user) {
    return this.http.get(`${this.baseurl}/sendmail/${user}`, this.httpOptions);
  }

}
