<!-- Basic form layout section start -->
<section id="basic-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">Producció Empreses</div>
            <p class="content-sub-header">Kg produïts per cada empresa i percentatge</p>
        </div>
    </div>

    <div class="row text-left" *ngIf="inicialitzat && !single?.length else alteer">
        <div class="col-md-12 col-lg-6">
            <ngx-skeleton-loader
            count="1"
            [theme]="{ 
                'border-radius': '0px',
                height: '118px'
            }"></ngx-skeleton-loader>
        </div>
        
        <div class="col-md-12 col-lg-6">
            <ngx-skeleton-loader
            count="1"
            [theme]="{ 
                'border-radius': '0px',
                height: '118px'
            }"></ngx-skeleton-loader>
        </div>
        
        <div class="col-md-12">
            <ngx-skeleton-loader
            count="1"
            [theme]="{ 
                'border-radius': '0px',
                height: '445px'
            }"></ngx-skeleton-loader>
        </div>
    </div>

    <ng-template #alteer class="row text-left">
        <div class="col-md-12">
            <div *ngIf="inicialitzat else elseBlock">

                <section id="html-headings-default">

                    <div class="row" matchHeight="card">
                        <div class="col-md-12 col-lg-6">
                            <div class="card">
                                <div class="card-content">
                                    <div class="px-3 py-3">
                                        <div class="media">
                                            <div class="media-body text-left">
                                                <h3 class="mb-1 green">{{pcentpk | number:'1.2-2'}} %</h3>
                                                <span>PROSMOKIWI MARE: {{kgpk}} Kg</span>
                                            </div>
                                            <div class="media-right align-self-center">
                                                <i class="icon-pie-chart green font-large-2 float-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <div class="card">
                                <div class="card-content">
                                    <div class="px-3 py-3">
                                        <div class="media">
                                            <div class="media-body text-left">
                                                <h3 class="mb-1 green">{{pcentgpk | number:'1.2-2'}} %</h3>
                                                <span>GERMANS PROSMOKIWI: {{kggpk}} Kg</span>
                                            </div>
                                            <div class="media-right align-self-center">
                                                <i class="icon-pie-chart green font-large-2 float-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-content">
                                        <div id="line-chart1" class="height-400">
                                            <ngx-charts-pie-grid [scheme]="colorScheme" [results]="single" [animations]="true">
                                            </ngx-charts-pie-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <button class="btn btn-raised btn-danger mr-2" (click)="delPercent()">
                    <i class="icon icon-refresh font-medium-3 p-1"></i> Resetejar KiwiBot</button>
            </div>

            <ng-template #elseBlock>
                <div class="card">
                    <div class="card-content">
                        <div class="px-3 pt-2">
                            <form [formGroup]="percentForm" (ngSubmit)="onFormSubmit(percentForm.value)" class="form form-horizontal form-bordered">
                                <div class="form-body">
                                    <h4 class="form-section"><i class="ft-pie-chart"></i>Produccions en kg</h4>
                                    <div class="form-group row">
                                        <label class="col-md-3 label-control" for="nomclient">Kg Prosmokiwi Mare</label>
                                        <div class="col-md-9">
                                            <div class="position-relative has-icon-left">
                                                <input type="number" min="1" id="kgpk" class="form-control" placeholder="Kg Prosmokiwi Mare" formControlName="kgpk">
                                                <div class="form-control-position">
                                                    <i class="ft-info"></i>
                                                </div>
                                                <div class="validation-errors ">
                                                    <div class="error-message" *ngIf="!percentForm.get('kgpk').valid && percentForm.get('kgpk').touched">
                                                        <p class="msg-validacio mt-2">
                                                            Introdueix els kg, valor mínim 1
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row last">
                                        <label class="col-md-3 label-control" for="nomclient">Kg Germans Prosmokiwi</label>
                                        <div class="col-md-9">
                                            <div class="position-relative has-icon-left">
                                                <input type="number" min="1" id="kggpk" class="form-control" placeholder="Kg Germans Prosmokiwi" formControlName="kggpk">
                                                <div class="form-control-position">
                                                    <i class="ft-info"></i>
                                                </div>
                                                <div class="validation-errors ">
                                                    <div class="error-message" *ngIf="!percentForm.get('kggpk').valid && percentForm.get('kggpk').touched">
                                                        <p class="msg-validacio mt-2">
                                                            Introdueix els kg, valor mínim 1
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-actions">
                                    <div class="float-right ">
                                        <button type="submit " [disabled]="!percentForm.valid " class="btn btn-raised btn-primary mr-2 ">
                                            <i class="icon icon-rocket font-medium-3 p-1"></i> Inicialitzar KiwiBot
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </ng-template>

        </div>
    </ng-template>

</section>
<!-- // Basic form layout section end -->