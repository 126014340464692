import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from "./app.component";
// import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";

import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './shared/auth/auth-guard.service';

import { PerfilsComponent } from './perfils/perfils.component';
import { DataTablesModule } from 'angular-datatables';
import { ClientsComponent } from './clients/clients.component';
import { CalibresComponent } from './calibres/calibres.component';
import { PercentComponent } from './percent/percent.component';
import { VendesComponent } from './vendes/vendes.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InstruccionsComponent } from './instruccions/instruccions.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RegistresComponent } from './registres/registres.component';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    // ContentLayoutComponent,
    LoginComponent,
    PerfilsComponent,
    ClientsComponent,
    CalibresComponent,
    PercentComponent,
    VendesComponent,
    InstruccionsComponent,
    ErrorPageComponent,
    RegistresComponent
  ],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    FormsModule,
    NgxChartsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR KEY"
    }),
    PerfectScrollbarModule,
    NgxSkeletonLoaderModule
  ],
  providers: [
    AuthGuardService,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
