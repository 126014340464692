<!--Extended Table starts-->
<div class="row text-left">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <div class="content-header">Instruccions d'Ús</div>
                <p class="content-sub-header">Configuracions inicials i funcionament</p>
            </div>
        </div>
    </div>
</div>
<section id="extended">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="card">

                <div class="card-content">
                    <div class="card-header d-flex align-items-center">
                        <h4 class="border-bottom">Configuració</h4>
                    </div>
                    <div class="card-body">
                        <ol>
                            <li>Entrar els kg produïts per cada empresa: Configurar Dades -> Inicialitzar</li>
                            <li>Entrar els clients: Configurar Dades -> Clients</li>
                            <li>Entrar els calibres: Configurar Dades -> Calibres</li>
                            <li>Entrar els kg produïts de cada calibre: Configurar Dades -> Calibres</li>
                        </ol>

                        <p style="color:inherit">Un cop entrades totes les dades anteriors ja es poden començar a entrar vendes.</p>

                        <p style="color:inherit">NOTA IMPORTANT: Si s'intenta eliminar un client o un calibre que intervingui en una venda el sistema donarà error fins que no s'elimini la venda.</p>

                    </div>

                    <div class="card-header d-flex align-items-center">
                        <h4 class="border-bottom">Despeses</h4>
                    </div>
                    <div class="card-body">
                        <p style="color:inherit">Les despeses s'introduiran només un cop a l'any, un cop finalitzada la campanya.</p>

                        <p style="color:inherit">Les despeses poden ser comuns (totes) o bé de cada una de les empreses.</p>

                    </div>

                    <div class="card-header d-flex align-items-center">
                        <h4 class="border-bottom">Consultes</h4>
                    </div>

                    <div class="card-body">
                        <h5>Vendes mensuals</h5>
                        <p style="color:inherit">Les vendes mensuals mostren, de cada mes que dura la campanya, el nombre de kg venuts i euros ingressats de cada mes. De novembre a maig.</p>

                        <h5>Despeses, ingressos bruts i ingressos nets</h5>
                        <p style="color:inherit">Al següent apartat es mostra el percentatge de producció que li pertoca a cada empresa i també mostra l'ingrès brut a partir d'aquest percentatge.</p>
                        <p style="color:inherit">A continuació es mostra la despesa total (sumatori de totes les despeses). Mostra també el total de despesa comú (despeses comunes) i al costat la despesa de cada una de les empreses i l'ingrès net de cada una d'elles. A sota tenim
                            l'ingrés net total, es a dir, la suma dels ingressos nets de les dos empreses.</p>

                        <p style="color:inherit"><strong>Calcul ingrés net:</strong> L'ingrés net es calcula seguint la següent formula:<br /> Ingrés net = Ingrés brut - despeses de l'empresa - (despeses comuns * percentatge producció)
                        </p>

                        <h5>Kg venuts calibres</h5>
                        <p style="color:inherit">En aquest apartat tenim un gràfic on podem veure per a cada calibre els kg produïts i els que portem venuts.</p>

                        <h5>Calibres segons producció</h5>
                        <p style="color:inherit">Gràfic circular amb els kg venuts segons el calibre.</p>

                        <h5>Vendes segons client</h5>
                        <p style="color:inherit">Aquesta taula es va creant de forma automàtica amb el sumatori de totes les vendes de cada client. Estan ordenades per la columna 'total pagat', es a dir, primer apareix el client que més ens ha comprat.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Extended Table Ends-->