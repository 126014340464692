<!--Extended Table starts-->
<div class="row text-left">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <div class="content-header">Gestió Calibres</div>
                <p class="content-sub-header">Llistat calibres temporada actual</p>
            </div>
            <div class="col-6">
                <div class="content-header text-right">
                    <button class="btn btn-outline-primary" routerLink="noucalibre"><i class="ft-plus font-medium-3 p-1"></i> Afegir Calibre</button>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="extended">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="card">

                <div class="card-content">
                    <div class="card-body">
                        
                        <table class="table table-responsive-md text-center table-bordered table-sm row-border hover" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions">
                            <thead>
                                <tr>
                                    <th>Número</th>
                                    <th>Pes (gr)</th>
                                    <th style="width: 100px">Preu</th>
                                    <th>Calibre</th>
                                    <th style="min-width: 120px">Producció (kg)</th>
                                    <th>Accions</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let c of calibres">
                                    <td>{{ c.numcal }}</td>
                                    <td>{{ c.pesos }}</td>
                                    <td>{{ c.preu }}</td>
                                    <td>{{ c.calibre }}</td>
                                    <td>{{ c.kgcal }}</td>
                                    <td>
                                        <a [routerLink]="['editacalibre', c._id]" class="success p-0 mr-2 bto-actions">
                                            <i class="icon icon-pencil font-medium-3 p-1"></i>
                                        </a>
                                        <a class="danger p-0 bto-actions" (click)="delCalibre(c)">
                                            <i class="icon icon-trash font-medium-3 p-1"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="row text-left" *ngIf="!calibres?.length && !flagNull">
                            <div class="col-sm-12">
                                <ngx-skeleton-loader count="10" animation="pulse"></ngx-skeleton-loader>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Extended Table Ends-->