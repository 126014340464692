import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class VendesService {

  baseurl = environment.serverUrl;

  token = localStorage.getItem('jwt');

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'X-Access-Token': this.token })
  };

  constructor( private http: HttpClient ) { }

  // GET
  GetVendes() {
    return this.http.get(this.baseurl + '/vendes', this.httpOptions);
  }

  // GET x id
  GetVenda(id) {
    return this.http.get(`${this.baseurl}/vendes/${id}`, this.httpOptions);
  }

  // UPDATE
  UpdateVenda(id, venda) {
    return this.http.put(`${this.baseurl}/vendes/${id}`, venda, this.httpOptions);
  }

  // POST
  SetVenda(venda) {
    return this.http.post(this.baseurl + '/vendes', venda, this.httpOptions);
  }

  // DELETE
  DelVenda(id: any) {
    return this.http.delete(`${this.baseurl}/vendes/${id}`, this.httpOptions);
  }

  GetNumVendes() {
    return this.http.get(this.baseurl + '/num-vendes', this.httpOptions);
  }

  GetProduccio() {
    return this.http.get(this.baseurl + '/total-produccio', this.httpOptions);
  }

  GetKgVenuts() {
    return this.http.get(this.baseurl + '/kg-venuts', this.httpOptions);
  }

  GetImportBrut() {
    return this.http.get(this.baseurl + '/import-brut', this.httpOptions);
  }

}
