import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PercentService {

  baseurl = environment.serverUrl;

  token = localStorage.getItem('jwt');

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'X-Access-Token': this.token })
  };

  constructor( private http: HttpClient ) { }

   // GET
   GetPercent() {
    return this.http.get(this.baseurl + '/percent', this.httpOptions);
  }

  // POST
  SetPercent(percent) {
    return this.http.post(this.baseurl + '/percent', percent, this.httpOptions);
  }

  // DELETE
  DelPercent() {
    return this.http.delete(this.baseurl + '/percent', this.httpOptions);
  }

}
