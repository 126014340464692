import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  baseurl = environment.serverUrl;

  token = localStorage.getItem('jwt');

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'X-Access-Token': this.token })
  };

  constructor( private http: HttpClient ) { }

  // GET
  GetClients() {
    return this.http.get(this.baseurl + '/clients', this.httpOptions);
  }

  // GET x id
  GetClient(id) {
    return this.http.get(`${this.baseurl}/clients/${id}`, this.httpOptions);
  }

  // UPDATE
  UpdateClient(id, client) {
    return this.http.put(`${this.baseurl}/clients/${id}`, client, this.httpOptions);
  }

  // POST
  SetClient(client) {
    return this.http.post(this.baseurl + '/clients', client, this.httpOptions);
  }

  // DELETE
  DelClient(id: any) {
    return this.http.delete(`${this.baseurl}/clients/${id}`, this.httpOptions);
  }

  GetNumClients() {
    return this.http.get(this.baseurl + '/num-clients', this.httpOptions);
  }

}
