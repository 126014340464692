import { Component, OnInit, LOCALE_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ToastService } from 'app/shared/services/toastr.service';
import { PercentService } from '../services/percent.service';
import { LoginService } from '../shared/auth/login.service';

import swal from 'sweetalert2';
import { browserRefresh } from 'app/app.component';

@Component({
  selector: 'app-percent',
  templateUrl: './percent.component.html',
  styleUrls: ['./percent.component.css'],
  providers: [ToastService, { provide: LOCALE_ID, useValue: 'es' }]

})
export class PercentComponent implements OnInit {

  view: any[] = [900, 400];

  colorScheme = {
      domain: ["#197775", "#373357"]
  };

  percentForm: FormGroup;
  kgpk;
  kggpk;

  pcentpk;
  pcentgpk;

  inicialitzat = true;

  single: any = [];
  browserRefresh: boolean;

  constructor( private formBuilder: FormBuilder,
               private servei: PercentService,
               private toast: ToastService,
               private login: LoginService ) { }

  ngOnInit(): void {

    this.browserRefresh = browserRefresh;
    if ( browserRefresh ) {
      this.login.logOut();
    }

    this.servei.GetPercent()
    .subscribe( ( data ) => {

      if ( data['percent'].length === 0 ) {
        this.inicialitzat = false;
      } else {
        this.inicialitzat = true;

        this.kgpk = data['percent'][0].kg_prosmo;
        this.kgpk = this.kgpk.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.kggpk = data['percent'][0].kg_germans;
        this.kggpk = this.kggpk.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.pcentpk = data['percent'][0].percent_prosmo;
        this.pcentgpk = data['percent'][0].percent_germans;

        this.single = [
          {
            'name': 'Prosmokiwi Mare',
            'value': data['percent'][0].kg_prosmo
          },
          {
            'name': 'Germans Prosmokiwi',
            'value': data['percent'][0].kg_germans
          }
        ];
      }
    },
      (error) => {
          console.error(error);
      }
    );

    this.percentForm = this.formBuilder.group({
      'kgpk' : [null, Validators.compose([
        Validators.min(1),
        Validators.required
        ])],
      'kggpk' : [null, Validators.compose([
        Validators.min(1),
        Validators.required
        ])],
    });

  }

  delPercent() {
    swal.fire({
      title: 'Resetejar KiwiBot?',
      text: 'Segur que vols eliminar els kg de cada empresa?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancel·lar'
    }).then((result) => {
        if (result.value) {
          this.servei.DelPercent().subscribe( res => {
            if ( res['ok'] ) {
              this.inicialitzat = false;
              swal.fire(
                'KiwiBot Resetejat!',
                'Els kg han estat eliminats de la base de dades.',
                'success'
              );
            } else {
              swal.fire('Error', 'Error al eliminar!', 'error');
            }
          });
        }
    });
  }

  onFormSubmit(form: NgForm) {

    const kgpk = form['kgpk'];
    const kggpk = form['kggpk'];

    const kgtotals = kgpk + kggpk;

    const ppk = kgpk / kgtotals * 100;
    const pgpk = kggpk / kgtotals * 100;

    const percent = {
      'kgpk' : kgpk,
      'kggpk' : kggpk,
      'ppk' : ppk,
      'pgpk' : pgpk
    }

    this.servei.SetPercent(percent)
      .subscribe( response => {
        if (response['ok']) {
          this.toast.progressBar('KiwiBot inicialitzat correctament', response['percent'].user);
          this.ngOnInit();
        } else {
          this.toast.typeError(response['err'].message);
        }
      },
      error => {
        console.log(error);
      });
  }

}
