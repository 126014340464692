import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarisService {

  baseurl = environment.serverUrl;

  token = localStorage.getItem('jwt');

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'X-Access-Token': this.token })
  };

  usuaris = [];
  constructor( private http: HttpClient ) { }

  // GET
  GetUsuaris() {
    return this.http.get(this.baseurl + '/user', this.httpOptions);
  }

  // DELETE
  DelUsuaris(id: any) {
    return this.http.delete(`${this.baseurl}/user/${id}`, this.httpOptions);
  }

  // ADD
  AddUsuaris(nom: any, email: any, passwd: any ) {
    const usuari =  {
                "role": "ADMIN_ROLE",
                "email": email,
                "username": nom,
                "password": passwd
              }
    return this.http.post(`${this.baseurl}/user`, usuari, this.httpOptions);
  }

}
