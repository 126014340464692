import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalibresService {

  baseurl = environment.serverUrl;

  token = localStorage.getItem('jwt');

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'X-Access-Token': this.token })
  };

  constructor( private http: HttpClient ) { }

  // GET
  GetCalibres() {
    return this.http.get(this.baseurl + '/calibres', this.httpOptions);
  }

  // GET x id
  GetCalibre(id) {
    return this.http.get(`${this.baseurl}/calibres/${id}`, this.httpOptions);
  }

  // UPDATE
  UpdateCalibre(id, calibre) {
    return this.http.put(`${this.baseurl}/calibres/${id}`, calibre, this.httpOptions);
  }

  // POST
  SetCalibre(calibre) {
    return this.http.post(this.baseurl + '/calibres', calibre, this.httpOptions);
  }

  // DELETE
  DelCalibre(id: any) {
    return this.http.delete(`${this.baseurl}/calibres/${id}`, this.httpOptions);
  }

  GetNumCalibres() {
    return this.http.get(this.baseurl + '/num-calibres', this.httpOptions);
  }

}
