import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { LoginService } from '../shared/auth/login.service';
import { Router } from '@angular/router';
import { LogService } from '../services/log.service';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import swal from 'sweetalert2';
import { browserRefresh } from 'app/app.component';

@Component({
  selector: 'app-registres',
  templateUrl: './registres.component.html',
  styleUrls: ['./registres.component.css']
})
export class RegistresComponent implements OnInit, OnDestroy {

  dtOptions: DataTables.Settings = {};

  @ViewChild(DataTableDirective)

  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  isAdmin = false;
  regis = [];

  flagNull = false;
  browserRefresh: boolean;

  constructor( private login: LoginService,
               private router: Router,
               private log: LogService ) { }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {

    this.browserRefresh = browserRefresh;
    if ( browserRefresh ) {
      this.login.logOut();
    } else {
      if (this.login.getUser() !== 'admin') {
        this.router.navigate(['dashboard']);
      } else {
        this.isAdmin = true;
        this.getLog();
      }
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: [[ 2, 'desc' ]],
      language: {
        'decimal': ',',
        'emptyTable': 'Sense informació',
        'info': 'De _START_ a _END_ de _TOTAL_ Registres',
        'infoEmpty': 'Mostrant 0 a 0 de 0 Registres',
        'infoFiltered': '(Filtrat de _MAX_ total registres)',
        'infoPostFix': '',
        'thousands': '.',
        'lengthMenu': 'Mostrar _MENU_ Registres',
        'loadingRecords': 'Carregant...',
        'processing': 'Processant...',
        'search': 'Cercar:',
        'zeroRecords': 'Sense resultats',
        'paginate': {
            'first': 'Primer',
            'last': 'Darrer',
            'next': 'Següent',
            'previous': 'Anterior'
        }
      }
    };

  }

  async getLog() {
    await this.log.GetLog()
    .subscribe( ( data ) => {
        this.regis = data['logs'];
        if (this.regis.length < 1) {
          this.flagNull = true;
        }
        this.dtTrigger.next();
    },
        (error) => {
            console.error(error);
        }
    );
  }

  delRegis() {
    swal.fire({
      title: 'Eliminar tot el log?',
      text: "Segur que vols eliminar tot el registre d'accessos?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancel·lar'
    }).then((result) => {
        if (result.value) {
          this.log.delRegis().subscribe( res => {
            if ( res['ok'] ) {
              this.regis = [];
              this.flagNull = true;
              swal.fire(
                'Registres eliminats!',
                'Els registres han estat eliminats de la base de dades.',
                'success'
              );
              this.rerender();
            } else {
                swal.fire('Error', 'Error al eliminar!', 'error');
                console.log(res);
            }
          });
        }
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
   }

}
