<!--Extended Table starts-->
<div class="row text-left">
    <div class="col-12">
        <div class="content-header">Perfils d'Usuari</div>
        <p class="content-sub-header">Usuaris administradors del Kiwibot</p>
    </div>
</div>
<section id="extended">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="card">
                <div class="row">
                    <div class="col">
                        <div class="card-header text-left">
                            <h4 class="card-title">Usuaris</h4>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-header text-right">
                            <button class="btn btn-outline-primary" (click)="addUser()"><i class="icon icon-user-follow font-medium-3 p-1"></i> Afegir Usuari</button>
                        </div>
                    </div>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <table class="table table-responsive-md text-center">
                            <thead>
                                <tr>
                                    <th>Usuari</th>
                                    <th>Email</th>
                                    <th>Accions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let u of usuaris">
                                    <td>{{ u.username }}</td>
                                    <td>{{ u.email }}</td>
                                    <td class="t-actions">
                                        <a class="danger p-0 bto-actions" (click)="delUser(u._id)">
                                            <i class="icon icon-trash font-medium-3 p-1"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row text-left" *ngIf="!usuaris?.length">
                            <div class="col-sm-12">
                                <ngx-skeleton-loader count="10" animation="pulse"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Extended Table Ends-->